<template>
  <div class="req-temp-container">
    <div>
      <vs-row>
        <vs-col vs-w="4" class="p-2">
          <vs-card class="mt-6 bankCard" v-if="automatcher || payId">
            <div slot="header">
              <div class="items-center">
                <h5 class="font-medium mb-2">Your account at {{ appName }}</h5>
                <p><span>Use this account for all payments to Rello</span></p>
              </div>
            </div>
            <div v-if="automatcher">
              <div class="flex">
                <div class="w-full">
                  <label class="vs-input--label">Account name</label>
                  <p class="darkBlue font-normal">{{ automatcher.bankAccountName }}</p>
                </div>
              </div>
              <div class="flex my-8">
                <div class="w-full">
                  <label class="vs-input--label">BSB</label>
                  <p class="darkBlue font-normal">{{ automatcher.bsb }}</p>
                </div>
                <div class="w-full">
                  <label class="vs-input--label">Account number</label>
                  <p class="darkBlue font-normal">{{ automatcher.bankAccountNumber }}</p>
                </div>
              </div>
            </div>
            <div v-if="payId">
              <div class="flex" v-if="payId.payId">
                <div class="w-full">
                  <label class="vs-input--label">PayID</label>
                  <p class="darkBlue font-normal">{{ payId.payId }}</p>
                </div>
              </div>
              <div class="flex mt-2" v-if="payId.payIdName">
                <div>
                  <label class="vs-input--label">PayID Name</label>
                  <p class="darkBlue font-normal">{{ payId.payIdName }}</p>
                </div>
              </div>
            </div>
          </vs-card>
          <div v-else>
            <p>No automatcher account found</p>
          </div>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PlatformBankAccounts",

  data() {
    return {
      appName: process.env.VUE_APP_NAME == "RelloPay" ? "Rello" : "FlipPay",
      automatcher: null,
      payId: null,
    };
  },

  methods: {
    ...mapActions("partner", ["fetchAutomatcherAccount"]),

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    async getAutomatcherAccount() {
      this.$vs.loading();
      const partnerId = this.user.userType == "admin" ? this.user._id : this.user.partnerId;

      await this.fetchAutomatcherAccount(partnerId).then((response) => {
        if (response.data.data) {
          this.automatcher = response.data.data.automatcher;
          this.payId = response.data.data.payId;
        }

        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showMessage(ex.response.data.title || "Bank", ex.response.data.message, "danger", "icon-check-circle");

        if (ex.response.status === 400) {
          this.$router.push({ name: "settings" });
        }
      });
    },
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
  },

  mounted() {
    this.getAutomatcherAccount();
  },
};
</script>

